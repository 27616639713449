import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import AboutCenterSlide1 from "../images/aboutCenterSlide1.jpg"
import AboutCenterSlide2 from "../images/aboutCenterSlide2.jpg"
import AboutCenterSlide3 from "../images/aboutCenterSlide3.jpg"
import { NavLink } from 'react-router-dom';

const AboutCenter = () => {

    // const settings = {
    //     dots: false,
    //     // infinite: true,
    //     speed: 1000,
    //     autoplay: false,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     // centerMode:true

    // };

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);

    });


    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        asNavFor: '.slider-nav'
    };

    const settingsThumbs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false, arrows: false,
        centerMode: false,
        swipeToSlide: true,
        focusOnSelect: true,
        centerPadding: '10px'
    };






    return (
        <div className="container">
           {/* <div className="aboutCenterCont">
            <h1>About Center</h1>
               
            <div className="aboutCenterSlideCont">
                <div className="aboutCenterSlider">
                    <Slider {...settings} >
                        <div className="slide">slide 1</div>
                        <div className="slide">slide 2</div>
                        <div className="slide">slide 3</div>
                    </Slider>
                </div>

            </div>
            </div>  */}
            <h1 className="aboutCenterHeading">About Center</h1>
            <div className="aboutCenterSlider">

                <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={slider => (setSlider1(slider))}>

                    
                        
                        <div className="slick-slide">
                        <img className="slick-slide-image" src={AboutCenterSlide1} />
                        </div>
                        <div className="slick-slide">
                        <img className="slick-slide-image" src={AboutCenterSlide2} />
                        </div>
                        <div className="slick-slide">
                        <img className="slick-slide-image" src={AboutCenterSlide3} />
                        </div>                   
                        <div className="slick-slide">
                        <img className="slick-slide-image" src={AboutCenterSlide3} />
                        </div>                   

                </Slider>
                <div className="thumbnail-slider-wrap">
                    <Slider
                        {...settingsThumbs}
                        asNavFor={nav1}
                        ref={slider => (setSlider2(slider))}>
                        <div className="slick-slide">
                            <img className="slick-slide-image" src={AboutCenterSlide1}  />
                        </div>                  
                        <div className="slick-slide">
                            <img className="slick-slide-image" src={AboutCenterSlide2}/>
                        </div>                  
                        <div className="slick-slide">
                            <img className="slick-slide-image" src={AboutCenterSlide3}/>
                        </div>                  
                        <div className="slick-slide">
                            <img className="slick-slide-image" src={AboutCenterSlide3}/>
                        </div>                  

                    </Slider>
                </div>
                <div className="centerStoryText">
                    <h1>Get the Atmosphere Of Awakening</h1>
                    <p>Our patients are staying at the spot that in a unique way inspires the confidence in themselves, in their strength, and in the effective healing.</p><p style={{ "marginTop": "30px" }}><NavLink className="btn-more filled" to="/about">More About Us </NavLink></p>
                </div>
            </div>





        </div>
    )
}

export default AboutCenter
