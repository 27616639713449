import React from 'react'
import HeroSlider2 from '../components/HeroSlider2'
import AboutBoxes from '../components/AboutBoxes'
import WelcomeToUmeed from '../components/WelcomeToUmeed'
import TreatmentPrograms from '../components/TreatmentPrograms'
import { Helmet } from 'react-helmet'
import StoriesOfRecovery from '../components/StoriesOfRecovery'
import AboutCenter from '../components/AboutCenter'
import Footer from '../components/Footer'


const Home = () => {
    return (
        <>
            <Helmet>
                <title>Umeed Wellness Centre for Drugs, Alcohol Rehab in New Delhi</title>
                <meta name="description" content="Rehab Centre for De addiction from Alcohol, Drugs in New Delhi. Provide In-house patient care, Withdrawal management, counseling, Psychosocial intervention." />
            </Helmet>
            <HeroSlider2/>
            <AboutBoxes/>
            <WelcomeToUmeed/>
            <TreatmentPrograms/>
            <StoriesOfRecovery/>
            <AboutCenter/>
            <Footer/>
        </>
    )
}

export default Home
