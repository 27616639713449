import React, { useState }  from 'react'
import AboutBoxesApi from '../api/AboutBoxesApi'

const AboutBoxes = () => {

const [workData] = useState(AboutBoxesApi);
// console.log(workData)



    return (
        <section className="aboutBoxCont"> 
        <div className="container">      
            <div className="aboutBoxesCont d-flex flex-wrap justify-content-center ">
            {workData.map((curElem, i) => { 
                const { iconUrl, boxTitle, descText} = curElem;
                return(
                    <div className="aboutColumn d-flex flex-column align-items-center" key= { i }>
                        <div className="icon" ><img src={iconUrl} alt={boxTitle}/></div>
                            <h5>{boxTitle}</h5>
                            <p>{descText}</p>
                            <div><a href="/about" className="btn-more">LEARN MORE</a></div>
                    </div>
                )
            })}
            

           </div> 
           </div>
        </section>
    )
}

export default AboutBoxes
