
import Switch from 'react-bootstrap/esm/Switch';
import { Route } from 'react-router';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import TreatmentPrograms from './pages/TreatmentPrograms';
import Facility from './pages/Facility';
import RecoveryStories from './pages/RecoveryStories'
import Faqs from './pages/Faqs';
import Contact from './pages/Contact';
import Header from './components/Header';

function App() {
  return (
    <>
      <Header />
    <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/treatment-programs" component={TreatmentPrograms}/>
        <Route exact path="/facility" component={Facility}/>
        <Route exact path="/recovery-stories" component={RecoveryStories}/>
        <Route exact path="/faqs" component={Faqs}/>
        <Route exact path="/contact" component={Contact}/>
    </Switch>

    </>
  );
}

export default App;
