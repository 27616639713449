import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'


const About = () => {

    return (
        <>
            <Helmet>
                <title>About | Umeed Wellness Centre</title>
            </Helmet>
            About

        </>
    )
}

export default About
