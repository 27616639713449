import React from 'react'

const RecoveryStories = () => {
    return (
        <div>
            RecoveryStories
        </div>
    )
}

export default RecoveryStories
