import React from 'react'

const Facility = () => {
    return (
        <div>
            Facility
        </div>
    )
}

export default Facility
