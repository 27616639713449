import React from 'react'
import treatmentImage from '../images/treatment-program.webp'
import { NavLink } from 'react-router-dom';


const TreatmentPrograms = () => {
    return (
        <>
            <div className="container">
                <div className="treatmentProgram">
                    <h1>Treatment Programs</h1>
                    <div className="programItemContainer">

                        <div className="programItem">
                            <div className="imageCont">
                                <img src={treatmentImage} />
                                <div className="programduration">10 Week Program</div>
                            </div>
                            <div className="itemContent">
                                <h2><a>Family Recover Program</a></h2>
                                <p>We’ll provide your family members with valuable knowledge about the disease of addiction.</p>
                                <div className="btn-cont"><a  className="btn-more">LEARN MORE</a></div>
                            </div>
                        </div>
                        <div className="programItem">
                            <div className="imageCont">
                                <img src={treatmentImage} />
                                <div className="programduration">10 Week Program</div>
                            </div>
                            <div className="itemContent">
                                <h2><a>Family Recover Program</a></h2>
                                <p>We’ll provide your family members with valuable knowledge about the disease of addiction.</p>
                                <div className="btn-cont"><a  className="btn-more">LEARN MORE</a></div>
                            </div>
                        </div>
                        <div className="programItem">
                            <div className="imageCont">
                                <img src={treatmentImage} />
                                <div className="programduration">10 Week Program</div>
                            </div>
                            <div className="itemContent">
                                <h2><a>Family Recover Program</a></h2>
                                <p>We’ll provide your family members with valuable knowledge about the disease of addiction.</p>
                                <div className="btn-cont"><a  className="btn-more">LEARN MORE</a></div>
                            </div>
                        </div>
                        <div className="programItem">
                            <div className="imageCont">
                                <img src={treatmentImage} />
                                <div className="programduration">10 Week Program</div>
                            </div>
                            <div className="itemContent">
                                <h2><a>Family Recover Program</a></h2>
                                <p>We’ll provide your family members with valuable knowledge about the disease of addiction.</p>
                                <div className="btn-cont"><a  className="btn-more">LEARN MORE</a></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TreatmentPrograms
