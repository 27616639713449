 const AboutBoxData = [  
     { id:1,
       iconUrl: './icons/counselling.png',
       boxTitle: "Pre-admission counselling",
       descText:'“RECOVERY OVER ADDICTION IS POSSIBLE, WITH HELP” Our programme is based on personal and professional experience of what works in setting yourself up for sustainable success.'
    }, 
    {
       id: 2,
        iconUrl: './icons/patient-care.png',
        boxTitle: "In patient care",
        descText: "Our programme is a 90 days inhouse transformational experience that bridges the gap between treatment and living on your own.",
    }, 
    {
       id: 3,
        iconUrl: './icons/wellness.png',
        boxTitle: "Wellness",
      descText: `“Wellness is an active process of becoming aware of and making choices towards a healthy and fulfilling life”  …… a state of complete physical, mental and social well-being, and not merely the absence of disease or infirmity.”`,
    }, 
    
 ];

 export default AboutBoxData;