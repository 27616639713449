import React from 'react';
import logo from '../images/umeed-logo.png';

const Footer = () => {
    return (
        <>  <div className="container">
                <footer className="d-flex">
                <div className="footerLeft">
                    <div className="logoSection">
                        <p>
                            <img src={logo} width="80" alt="Umeed Wellness Center" />
                        </p>
                        <p>We provide a healthy environment uniquely suited to support your growth and healing.</p>
                        <ul className="socialIcon">
                            <li><a href="" className="twitter"><i className="fa fa-twitter"></i></a></li>
                            <li><a href="" className="youtube"><i className="fa fa-youtube"></i></a></li>
                            <li><a href="" className="facebook"><i className="fa fa-facebook"></i></a></li>
                        </ul>
                    </div>
                    <div className="addrsCont">
                        <div>
                            <div className="icons"><i className="fa fa-map-marker"></i></div>
                            <div>H-10/A, Ansal Villas, Block H, Satbari Farms, Near Guru Amar Dass Ji Gurudwara, Chhatarpur, <br/>
                                New Delhi-110074</div>
                        </div>
                        <div>
                            <div className="icons"><i className="fa fa-envelope"></i></div>
                            <div><a href="mailto:umeed.in@gmail.com">umeed.in@gmail.com</a></div>
                        </div>
                        <div>
                            <div className="icons"><i className="fa fa-phone"></i></div>
                            <div>
                                <a href="tel:+91 9971908875">+91 9971908875</a>
                                <a href="tel:+91 8800727020">+91 8800727020 </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footerRight">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7014.450790686146!2d77.20750926838956!3d28.472757977856674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1e42d7c81ab%3A0xf89ed0a3209c7a66!2sUmeed%20Wellness%20Centre%20(drug%2Falcohol%20counseling%20rehab%20services)!5e0!3m2!1sen!2sin!4v1634301101313!5m2!1sen!2sin"  allowfullscreen="" loading="lazy"></iframe>
                </div>
                </footer>
            </div>
            <div className="copyrights">© Umeed Wellness Centre 2021</div>
        </>
    )
}

export default Footer
