import React from 'react';
import logo from '../images/umeed-logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Container } from 'react-bootstrap';
import { Navbar, Nav  } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';


const Header = () => {
    return (
        <>
        <header className="mainHeader">
            <div className="container">
                    <label className="mobileNumber"> <a href="tel:+918800727020"> <i className="fa fa-phone" aria-hidden="true"></i>  +91 8800727020</a></label>
            <Navbar sticky="top" expand="lg" >
                <Navbar.Brand>
                <NavLink className="nav-link" to="/"> <img src={logo} width="80" alt="Umeed Wellness Center" /> <span>Drug, Alcohol Rehab Centre New Delhi</span></NavLink>
                </Navbar.Brand>
            <Navbar.Toggle />
            
            <Navbar.Collapse>
            <Nav>
                <NavLink exact className="nav-link" to="/">Home</NavLink>
                    {/* <NavDropdown title="About">
                            <NavDropdown.Item href="home">Tea</NavDropdown.Item>
                            <NavDropdown.Item href="#">Coffee</NavDropdown.Item>
                   </NavDropdown> */}
                <NavLink exact className="nav-link" to="/about">About</NavLink>
                <NavLink exact className="nav-link" to="/treatment-programs">Treatment Programs</NavLink>
                <NavLink exact className="nav-link" to="/facility">Facility</NavLink>
                <NavLink exact className="nav-link" to="/recovery-stories">Recovery Stories</NavLink>
                <NavLink exact className="nav-link" to="/faqs">FAQs</NavLink>
                <NavLink exact className="nav-link" to="/contact">Contact</NavLink>
                <NavLink className="nav-link" to="Blog">Blog</NavLink>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
        </div>
        </header>
        </>
    )
}

export default Header
