import React from 'react'
import { Helmet } from 'react-helmet'

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Contact | Umeed Wellness Centre</title>
            </Helmet>
           Contact
        </>
    )
}

export default Contact
