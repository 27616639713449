import React, { useState} from 'react'
import { Button, Modal,  } from 'react-bootstrap'
import Slider from "react-slick";
// import Slide from "react-slick"
import ReactPlayer from 'react-player'




const StoriesOfRecovery = () => {


        const settings = {
            dots: false,
            // infinite: true,
            speed: 1000,
            autoplay: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            // centerMode:true

        };



    const [smShow, setSmShow] = useState(false);





    return (
        <>
        <div className="container">
        <div className="recoveryStoryCont">
            <h1>Watch the Stories <br /> Of Recovery</h1>
            <div className="story_PlayButton">
                <div className="playBtnCont">
                    <Button onClick={() => setSmShow(true)} className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8" fill="currentColor"></polygon></svg>
                    </Button>
                </div>
                <div className="someoneStory">
                    <h3>Sam’s Recovery Story</h3>
                    <p>Making the phone call for help can be a daunting first step for those struggling with addiction!</p>
                </div>
            </div>
            <div className="storyCarousal">
              
                <Slider {...settings} >
                    <div className="slide">
                        <ReactPlayer
                            url="https://www.youtube.com/embed/5pA2-7Loy_8"
                            playing
                            playIcon={<Button className="playBtn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg></Button>}
                            light="http://pinevale.axiomthemes.com/wp-content/uploads/2018/07/image-19-copyright.jpg"
                            />
                    </div>
                    <div className="slide">
                        <ReactPlayer
                            url="https://www.youtube.com/embed/5pA2-7Loy_8"
                            playing
                            playIcon={<Button className="playBtn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg></Button>}
                            light="http://pinevale.axiomthemes.com/wp-content/uploads/2018/07/image-19-copyright.jpg"
                        />
                    </div>
                    <div className="slide">
                        <ReactPlayer
                            url="https://www.youtube.com/embed/5pA2-7Loy_8"
                            playing
                            playIcon={<Button className="playBtn"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg></Button>}
                            light="http://pinevale.axiomthemes.com/wp-content/uploads/2018/07/image-19-copyright.jpg"
                        />
                    </div>
                </Slider>

            </div>
        </div>
        </div>  
        <Modal
            size="lg"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="" className="storiesPopup">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                    <iframe width="" height="" src="https://www.youtube.com/embed/5pA2-7Loy_8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default StoriesOfRecovery
