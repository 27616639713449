import SlideImage1 from "../images/banner1.jpg"
import SlideImage2 from "../images/banner2.jpg"
import SlideImage3 from "../images/banner3.jpg"
import React from "react";
import Slider from "react-slick";



const HeroSlider2 = () =>{

        const settings = {
            dots: true,
            infinite: true,
            speed: 1500,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1, 

        };

    return (
        <div className="container">
        <div className="heroSlider">
            <Slider {...settings} >                
                <div className="slide">
                <img src={SlideImage1} alt="Slide Banner" />
                </div>
                <div className="slide">
                <img src={SlideImage2} alt="Slide Banner" />
                </div>
                <div className="slide">
                <img src={SlideImage3} alt="Slide Banner" />
                </div>
            </Slider>
        </div>
        </div>
    );

}


export default HeroSlider2


