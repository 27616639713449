import React from 'react'
import welcomeImage from '../images/welcome-image.jpg'
import { NavLink } from 'react-router-dom';



const WelcomeToUmeed = () => {
    return (
        <>
        <section className="welcomeUmeedCont">
        <div className="container">
            <div className="d-flex">
            <div className="imageWithPhone">
                <div className="welcomeImage">
                <img src={welcomeImage} alt="Welcome to Umeed"/>
                </div>
                <div className="phoneWidget d-flex">
                    <div className="icon"><i className="fa fa-phone" aria-hidden="true"></i></div>
                    <div className="widgetNumber">
                        <label>GET 24/7 HELP NOW</label>
                        <a href="tel:+918800727020">   +91 8800727020</a>
                        <p>*All calls & messages are free and totally confidential.</p>
                    </div>
                </div>
            </div>
            <div className="welcomeTextCont">
                <h1>Welcome <br/> to Umeed Wellness Centre</h1>
                    <p>Umeed wellness centre is my tribute to my teacher Late Mr Oswald Periere popularly known as Ossie Sir. We are committed to help the victims of Alcoholism/Substance Abuse to be in recovery..... Girish (Gary)</p>
                    <p>ADDICTION IS A DISORDER OF THE MIND -THE BODY-THE SPIRIT</p>
                    <p>We are a leading drug/alcohol rehab, are intended to help those who have developed the dependency over alcohol or drugs and are finding it unable to live with drug/alcohol or without it., </p>
                    <p style={{"marginTop":"30px"}}><NavLink className="btn-more filled" to="/about">Read More..</NavLink></p>
            </div>
            </div>
        </div>
        </section>  
        </>
    )
}

export default WelcomeToUmeed
